import React from 'react';
import Footer from '../Footer';
import PrimaryContactForm from '../PrimaryContactForm';
import '../../App.css';

export default function SignUp() {

  return (
    <>

    <PrimaryContactForm />

    </>
  );

}
