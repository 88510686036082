import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import GalleryCarousel from './GalleryCarousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Image} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

function Cards() {
  return (
    <div className='cards'>
      <Container style={{ padding: "1em", margin: "2em" }}>
        <h1 style={{ fontFamily: "Georgia, serif" }} >We are a family owned business who is committed to excellence and bringing client visions to life.</h1>
      </Container>
      <GalleryCarousel />

      <Container style={{ padding: "1em", margin: "2em" }}>
        <Row xs={1} md={1} className="g-4">
          <Col>
            <div style={{width: 'auto', textAlign : "center"}}>
              <h2>Hiring Foreman</h2>
              <h3>
                Seeking an experienced foreman or carpenter with years of of on site experience.
              </h3>
              <div className='hero-btns' style={{textAlign : "center"}}>
                  <Button variant="dark" size="lg" href="/contact">
                    CONTACT FOR POSITION
                  </Button>
              </div>
           </div>
          </Col>
        </Row>
      </Container>

      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='/images/imgCarpentry.jpg'
              text='High quality carpentry with decades of experience'
              label='Carpentry'
              path='/services'
            />
            <CardItem
              src='/images/imgNewConstruction.jpg'
              text='Create your dream home from the ground-up,'
              label='New Construction'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='../images/imgRemodeling.jpg'
              text='Your vision is our priority'
              label='Renewing/Remodeling'
              path='/services'
            />
            <CardItem
              src='../images/MKO/approach.jpg'
              text='Experience. Expertise. Vision.'
              label='Our Approach'
              path='/services'
            />
          </ul>
        </div>
      </div>


    </div>

  );
}

export default Cards;
