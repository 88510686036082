import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import backgroundImg from '../../images/imgServices.jpg';
import img1 from '../../images/planning.jpg';
import img2 from '../../images/2.JPG';
import img3 from '../../images/3.JPG';
import img4 from '../../images/renovation.jpg';
import img5 from '../../images/const.jpg';
import img6 from '../../images/bp.jpg';
import img7 from '../../images/1.JPG';
import img8 from '../../images/5.JPG';
import img9 from '../../images/3.JPG';
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

export default function Services() {
  return (
    <>
      <Carousel fade>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img1}
            alt="First slide"
            height="450px"
          />
          <Carousel.Caption>
            <h3 style={{ fontFamily: "Georgia, serif", fontSize:"50px", color: "black" }}>Experience. Expertise. Vision.</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img5}
            alt="Second slide"
            height="450px"
          />

          <Carousel.Caption>
            <h3 style={{ fontFamily: "Georgia, serif", fontSize:"50px", color: "white" }}>Carpentry</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img2}
            alt="Third slide"
            height="450px"
          />

          <Carousel.Caption>
            <h3 style={{ fontFamily: "Georgia, serif", fontSize:"50px", color: "white" }}>Construction</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img4}
            alt="Fourth slide"
            height="450px"
          />

          <Carousel.Caption>
            <h3 style={{ fontFamily: "Georgia, serif", fontSize:"50px", color: "white" }}>Renewals & Remodeling</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <Container style={{ padding: "1em", margin: "2em" }}>
        <div style={{justifyContent: "center", textAlign: "center", fontSize: "100px"}} >
          <h1 style={{ fontFamily: "Georgia, serif", fontSize:"50px" }}>Services Gallery</h1>
        </div>
      </Container>

      <Row xs={1} md={2} className="g-4">
        <Col>
          <Card>
            <Card.Img variant="top"
              src={img6}  />
            <Card.Body>
              <Card.Title>Experience. Expertise. Vision.</Card.Title>
              <Card.Text>
                We are confident in our values, experience, and approach.
                Our familiy business prides itself in quality work and our flexibility to adapt to diverse work environments.
                The experience and professionalism our team has allows us to consistently deliver your vision.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Img variant="top"
              src={img7}  />
            <Card.Body>
              <Card.Title>Carpentry</Card.Title>
              <Card.Text>
                Carpentry is our specialty and we will use our skill to bring comfort to your home.
                Our diverse project portfolio has given us a concrete foundation to tackle complex projects.
                Your project is our opportunity to deliver on your vision.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
    </Row>
    <Row xs={1} md={2} className="g-4">
      <Col>
        <Card>
          <Card.Img variant="top"
            src={img8}  />
          <Card.Body>
            <Card.Title>Construction</Card.Title>
            <Card.Text>
              We are in the industry of building and constructing.
              Whether it be for leisure or utility, we are happy to create new spaces from the ground up.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card>
          <Card.Img variant="top"
            src={img9}  />
          <Card.Body>
            <Card.Title>Renewals & Remodeling</Card.Title>
            <Card.Text>
              We understand things break or simply get out of style.
              We can help you to bring your home/space back to life.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
  </Row>


    <Footer />

    </>
  );

}
